@use "./variables" as *;

@mixin tablet {
  @media (min-width: $tablet) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: $desktop) {
    @content;
  }
}

@mixin padding-sides {
  padding: $mob-pad-side;
}

@mixin custom-button {
  border-radius: 1rem;
  height: $mob-b-f-height;
}

@mixin border-top {
  border-top: solid 0.06rem;
  border-color: $color-cloud;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
