
/* Regular */
@font-face {
  font-family: "Avenir";
  src: url("../../assets/fonts/AvenirNextLTPro-Regular.woff2") format("woff2"),
    url("../../assets/fonts/AvenirNextLTPro-Regular.woff") format("woff"),
    url("../../assets/fonts/AvenirNextLTPro.ttf") format("truetype");
    font-weight: 400;
}

/* Demi */
@font-face {
  font-family: "Avenir";
  src: url("../../assets/fonts/AvenirNextLTPro-Demi.woff2") format("woff2"),
    url("../../assets/fonts/AvenirNextLTPro-Demi.woff") format("woff"),
    url("../../assets/fonts/AvenirNextLTProDemi.ttf") format("truetype");
  font-weight: 600;
}

/* Bold */
@font-face {
  font-family: "Avenir";
  src: url("../../assets/fonts/AvenirNextLTPro-Bold.woff2") format("woff2"),
    url("../../assets/fonts/AvenirNextLTPro-Bold.woff") format("woff"),
    url("../../assets/fonts/AvenirNextLTProBold.ttf") format("truetype");
  font-weight: 700;
}
