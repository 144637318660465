@use "../../assets/styles/partials/variables" as *;
@use "../../assets/styles/partials/typogrophy" as *;
@use "../../assets/styles/partials/mixins" as *;

h3 {
    font-size: 1rem;
    font-weight: 400;
    padding-top: 0.25rem;
    margin-left: 1vw;
    align-items: center;
}

.sidebar {
    width: 8vw;
    position: fixed;
    left: 0;
    background: $color-light-grey;
    transition: width 0.3s ease;
    overflow: hidden;
    padding: 10px 15px;

    &.closed {
        width: 3.125rem;
    }

    .menu-icons {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        &__last {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .icon {
            margin: 10px 0;
            width: 30px;
            height: auto;
            fill: white;

        }
    }
}