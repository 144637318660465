@use '../../styles/partials/variables' as *;
@use '../../styles/partials/typography' as *;
@use '../../styles/partials/mixins' as *;


.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.404);

    &__form {
        width: 70%;
        max-width: 310px;
        margin: auto;
        padding: 2em;
        background: white;
        border-radius: 20px;
        border: none;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        h2,
        p {
            text-align: center;
            color: #292828;
        }

        label {
            color: #292828;
            font-weight: 700;
            padding-left: 0.5vw;
            margin-bottom: 0px;
        }

        input {
            padding: 0.5rem;
            border: 1px solid #ddd;
            border-radius: 20px;

            &:focus {
                border-color: #0056b3;
                outline: none;
            }

            &::placeholder {
                color: #9b9494a2;
                opacity: 1;
            }
        }

        &--button {
            padding: 0.75em;
            background-color: #478bd4;
            color: white;
            border: none;
            border-radius: 15px;
            cursor: pointer;
            transition: background-color 0.3s;
            margin-top: 1vw;

            &:hover {
                background-color: #003d82;
            }
        }

        &--close {
            padding: 0.75em;
            background-color: white;
            border: none;
            cursor: pointer;
            background-image: url('../../assets/icons/close-24px.svg');
            background-position: right 0rem;
            background-size: 1.5rem;
            background-repeat: no-repeat;
        }

    }
}