@use "../../assets/styles/partials/variables" as *;
@use "../../assets/styles/partials/typogrophy" as *;
@use "../../assets/styles/partials/mixins" as *;

.header {
    font-family: "Titillium Web";
    background-color: white;
    padding: $mob-pad-side;
    height: 4rem;
    margin: 0;
    box-sizing: border-box;
    border-bottom: 1rem solid #f7f8f9;

    @include tablet {
        padding: $tab-pad-side;
        height: 7rem;

        @include desktop {
            padding: 0 5rem;
        }
    }

    &__nav {

        @include tablet {
            display: flex;
            justify-content: space-between;
            align-items: center;

        }
    }

    &__logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 3vw;
    }

    &__logo {
        padding-top: 4rem;
        max-width: 15vw;

        @include tablet {
            padding-bottom: 2rem;
            margin-top: 1rem;
            max-width: 30vw;
        }
    }

    &__nav-list {
        display: flex;
        justify-content: center;
        padding: 0;
        margin: 0;
    }

    &__nav-item {
        list-style: none;
        margin: 1rem;

        @include tablet {
            padding: 0.5rem;
            margin: 1rem 0 0 0;
        }
    }

    &__link {
        text-decoration: none;
        color: $color-cloud;
        padding: 0 2rem;
        font-weight: 700;

        @include tablet {
            padding: 0;
        }
    }
}

.active_link {
    text-decoration: none;
    background-color: $color-black;
    color: $color-white;
    border-radius: 1.5625rem;
    padding: 0.5rem 2rem;

    @include tablet {
        padding: 0.5rem;
    }
}