@use "../../assets/styles/partials/variables" as *;
@use "../../assets/styles/partials/typogrophy" as *;
@use "../../assets/styles/partials/mixins" as *;

.calendar {
    display: flex;
    flex-direction: column;
    max-width: 90%;
    margin: 20px auto;

    &__nav {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        button {
            padding: 8px 16px;
            background-color: #007bff;
            color: white;
            border: none;
            border-radius: 4px;
            cursor: pointer;

            &:hover {
                background-color: #0056b3;
            }
        }
    }

    &__header,
    &__row {
        display: grid;
        grid-template-columns: 150px repeat(7, 1fr);
        text-align: center;
    }

    &__cell {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 4rem;
        min-width: 6rem;
        border: 1px solid #ddd;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        border-radius: 2px;


        &--name {
            text-align: left;
            font-weight: bold;
        }

        &--current {
            background-color: #4c8ae654;
            color: white;
        }
    }

    &__header {
        background-color: #f0f0f0;
        font-weight: bold;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
    }

    &__row:not(:last-child) {
        border-bottom: 1px solid #ddd;
    }

    &__row:last-child .calendar__cell:first-child {
        border-bottom-left-radius: 25px;
    }

    &__row:last-child .calendar__cell:last-child {
        border-bottom-right-radius: 25px;
    }


    &__shift-tile {
        border-radius: 15px;
        padding: 1rem 2rem;
        margin: 0 1rem;
        max-width: 5rem;

        @include desktop {
            padding: 1rem 2rem;
            margin: 0 4rem;
        }

    }

    &__shift-tile--filled {
        background-color: rgba(101, 21, 104, 0.726);
        color: white;
        cursor: pointer;
    }

    &__search {
        max-width: 7rem;
        max-height: 2rem;
        border-radius: 15px;
        margin-left: 1.2vw;
        margin-top: 1rem;
        border: 0.5px solid grey;
        background-image: url(../../assets/icons/search-24px.svg);
        background-position: right 0.4rem;
        background-repeat: no-repeat;
        padding-left: 0.5rem;
    }


}