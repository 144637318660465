@font-face {
  font-family: "Titillium Web";
  src: url(../../fonts/TitilliumWeb-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: "Titillium Web";
  src: url(../../fonts/TitilliumWeb-SemiBold.ttf);
  font-weight: 600;
}