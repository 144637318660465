@use "../../assets/styles/partials/variables" as *;
@use "../../assets/styles/partials/mixins" as *;
@use "../../assets/styles/partials/typogrophy";

.heading {
    font-weight: 700;
    font-size: 1.5rem;
    color: $color-slate;
}

.list-details {
    display: flex;
    justify-content: space-between;
    font-family: "Titillium Web";
    width: 100%;
    @include border-top;
    @include padding-sides;

    &__sort-bar {
        display: none;
    }

    &__subheading {
        color: $color-slate;
        font-size: $mob-table-header-txt;
        font-weight: 600;
        margin-bottom: 0;
        margin-top: 0.5rem;
    }

    &__text {
        font-size: $mob-body-S-txt;
        font-weight: 400;
        margin: 0;
        margin-bottom: 0.5rem;
    }

    &__tablet {
        margin-top: 0.5rem;
        display: flex;
        position: relative;
        width: 100%;
    }

    &__link {
        font-size: $mob-body-S-txt;
        color: $color-indigo;
        text-decoration: none;
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
        font-weight: 600;
    }

    &__item {
        width: 50%;
    }

    &__contact {
        width: 50%;
        position: relative;
    }

    &__delete {
        position: absolute;
        left: 0rem;
        top: 9.5rem;
    }

    &__edit {
        position: absolute;
        top: 9.5rem;
        right: 3.5rem;
    }

    &__link-chevron {
        border: none;
        background-color: transparent;
        display: flex;
        align-items: center;
    }

    @include tablet {
        box-sizing: border-box;
        width: 100%;
        border-top: 0;
        flex-direction: column;
        align-items: center;
        box-shadow: 0 2px 10px #0000001a;
        padding-bottom: 5rem;
        margin-top: 0px;

        &__sort-bar {
            display: flex;
            width: 100%;
            height: 2.5rem;
            margin-top: 0;
            padding-left: 0;
            background-color: $color-light-grey;
            justify-content: space-around;
            align-items: center;
            @include padding-sides;
        }

        &__with-sort-icon {
            display: flex;
            align-items: center;
        }

        &__sort-bar-element {
            list-style-type: none;
            color: $color-slate;
            font-weight: 600;
            font-size: $tab-table-header-txt;
        }

        &__subheading {
            display: none;
        }

        &__item {
            display: flex;
            width: 33.3%;
        }

        &__contact {
            width: 50%;
            position: relative;
            display: flex;
            justify-content: space-between;
        }

        &__delete {
            top: 0.2rem;
            position: absolute;
            margin-top: 0rem;
            left: auto;
        }

        &__edit {
            position: absolute;
            top: 0.2rem;
            right: 50%;
        }

        &__text {
            width: 50%;
            display: flex;
            justify-content: flex-start;
        }

        &__link {
            width: 50%;
            display: flex;
            justify-content: flex-start;
        }
    }

    @include desktop {
        &__delete {
            top: 0.2rem;
            position: absolute;
            margin-top: 0rem;
            left: auto;
        }

        &__edit {
            position: absolute;
            top: 0.2rem;
        }
    }
}

.sort-bar {
    display: none;

    @include tablet {
        padding-left: 5vw;
        min-width: 75vw;
        padding-left: 1rem;
        display: flex;
        height: 2.5rem;
        margin-top: 0;
        background-color: #f7f8f9;
        align-items: center;
    }

    &__sort-icon {
        display: flex;
        align-items: center;
    }

    &__element {
        list-style-type: none;
        color: #5c667e;
        font-weight: 600;
        font-size: 0.75rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.stock-in {
    background-color: $color-tag-green;
    width: fit-content;
    font-size: $mob-body-S-txt;
    border-radius: 1rem;
    padding: 0 0.5rem;
    color: $color-green;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stock-out {
    background-color: $color-tag-red;
    width: fit-content;
    font-size: $mob-body-S-txt;
    border-radius: 1rem;
    padding: 0 0.5rem;
    color: $color-red;
    display: flex;
    justify-content: center;
    align-items: center;
}

.status-box {
    width: 50%;
}

.absolute-inventory-icons {
    @include tablet {
        position: absolute;
        right: 3%;
        width: 15%;
    }
}

.shift-entry {
    min-width: 80vw;
    display: flex;
    height: 2.5rem;
    margin-top: 0;
    align-items: center;
    justify-content: space-evenly;
    margin-right: 5vw;
}