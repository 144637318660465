@use "../../assets/styles/partials/variables" as *;
@use "../../assets/styles/partials/typogrophy" as *;
@use "../../assets/styles/partials/mixins" as *;

.dashboard-body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    box-sizing: border-box;
    margin-bottom: 5rem;

    &__sidebar {
        margin-right: 8vw;
    }

}