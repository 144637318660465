@use "../../assets/styles/partials/variables" as *;
@use "../../assets/styles/partials/typogrophy" as *;
@use "../../assets/styles/partials/mixins" as *;

.footer {
    background-color: $color-light-grey;

    &__container {
        height: 10vh;
        display: flex;
        justify-content: center;
        align-items: center;

        @include tablet {
            margin-top: 5rem;
            padding-top: 3rem;
        }
    }

    &__text {
        color: $color-slate;
        font-family: "Titillium Web";
        font-size: $mob-body-S-txt;
        line-height: $mob-body-S-lh;

        @include tablet {
            font-size: $tab-body-S-txt;
            line-height: $tab-body-S-lh;
        }
    }
}