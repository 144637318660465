// Import your variables and mixins as usual
@use "../../assets/styles/partials/variables" as *;
@use "../../assets/styles/partials/mixins" as *;

.admin-panel__flex-container {
    display: flex;
    justify-content: space-between;
}

.admin-panel__left,
.admin-panel__right {
    flex: 1;
}

.admin-panel__right {
    margin-left: 20px;
}


.admin-panel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background-color: $color-white;
    padding: 2rem;
    box-shadow: 0 2px 10px #0000001a;
    padding-right: 5rem;

    &__label {
        font-size: $mob-button-lab-link-txt;
        line-height: $mob-button-lab-link-lh;
        color: $color-slate;
        display: flex;
        flex-direction: column;
        margin-bottom: 0.75rem;
    }

    &__input {
        padding: 0.75rem;
        border: 1px solid $color-cloud;
        border-radius: $b-f-rad;
        font-size: $mob-button-lab-link-txt;
        line-height: $mob-button-lab-link-lh;

        &:focus {
            border-color: $color-indigo;
        }
    }

    &__group {
        display: flex;
        flex-direction: column;
    }

    &__nav {
        background: linear-gradient(to bottom, $color-light-grey, $color-white);
        margin-right: 5rem;
        border-radius: $b-f-rad;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

        &__list {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }

        &-item {
            padding: 0.75rem 1rem;
            border-bottom: 1px solid $color-cloud;
            transition: background-color 0.3s, transform 0.3s;
            list-style: none;
            border-radius: 20px;
            margin-right: 3rem;

            &:hover {
                background-color: $color-indigo;
                color: $color-white;
                cursor: pointer;
                transform: translateY(-2px);
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            }

            &--active {
                background-color: $color-indigo;
                color: $color-white;
                cursor: pointer;
                transform: translateY(-2px);
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            }
        }
    }

    &__actions {
        display: flex;
        justify-content: space-between;


    }

    &__button {
        padding: 0.75rem 1.5rem;
        border-radius: $b-f-rad;
        border: none;
        color: $color-white;
        cursor: pointer;
        background-color: $color-indigo;
        transition: background-color 0.3s, box-shadow 0.3s;

        &.pressed {
            background-color: darken($color-indigo, 10%);
        }

        &:hover {
            background-color: lighten($color-indigo, 10%);
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
        }

        &--cancel {
            background-color: $color-slate;

            &:hover {
                background-color: darken($color-slate, 10%);
            }
        }

        &--add {
            background-color: $color-indigo;

            &:hover {
                background-color: darken($color-green, 10%);
            }
        }
    }

    &__subheader {
        font-size: $mob-subheader-txt;
        line-height: $mob-subheader-lh;
        font-weight: $semi-bold;
        color: $color-indigo;
        margin-bottom: 1rem;
    }

    &__group {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;


    }

    &__content {
        min-width: 60vw;
        background: $color-white;
        padding: 1rem;
        border-radius: $b-f-rad;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);


    }

    &__form {
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;




    }

    &__section {
        background-color: $color-light-grey;
        padding: 1rem;
        border-radius: $b-f-rad;
        margin-bottom: 1rem;


    }
}